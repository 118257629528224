.form-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.join-form {
  position: relative;
  background-color: gray;
  padding: 2rem;
  border-radius: 8px;
  z-index: 1001;
  max-width: 400px;
  width: 100%;
}

.join-form h2 {
  color: orange;
  text-align: center;
  margin-bottom: 1.5rem;
}

.join-form input,
.join-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
}

.pay-now-btn {
  background-color: orange;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.pay-now-btn:hover {
  background-color: darkorange;
}
