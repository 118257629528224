.header {
  display: flex;
  justify-content: space-between; 
  align-items: center;         
  width: 100%;                     
  padding: 0 2rem; 
               
}

.logo {
  width: 10rem;
  height: auto;                   
}

.header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;                      
  margin: 0;                      
  padding: 0; 
  color: white; 
                    
}

.header-menu li {
             
  cursor: pointer;
}
.header-menu li:hover {
  color: orange;                  /* Change to orange on hover */
}
@media  screen and (max-width:768px){
  .header>:nth-child(2){
    position: fixed;
    right: 2rem;
    z-index: 99;
    top: 2rem;
  }
  .header-menu{
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
}
